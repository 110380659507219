import { getPricingContext, getPricingContextVatInclusivity, initializePricingContext, isPricingContextInitialized } from '../utils/pricingContext.util'

import pricingContextModuleHelpers from '@vp/pricing-context-module/helpers'
import { useCouponDetailsStore } from '../store/couponDetails.store'
import { useEffect } from 'react'

/**
 * Custom hook to handle pricing context initialization and context changes.
 *
 * @param tenant - The tenant (e.g., 'vistaprint').
 * @param market - The market (e.g., 'US').
 * @param developmentMode - Optional flag for enabling development-specific behavior.
 * @returns boolean - Returns true if pricing context is initialized.
 */

export const usePricingContext = (
  tenant: string,
  market: string,
  developmentMode?: boolean
) => {
  const setPricingContext = useCouponDetailsStore((state) => state.setPricingContext)
  const setVatIncl = useCouponDetailsStore((state) => state.setVatIncl)

  useEffect(() => {
    const updatePricingContextData = () => {
      setPricingContext(getPricingContext())
      setVatIncl(getPricingContextVatInclusivity())
    }

    const handleInitializedEvent = () => {
      const initialized = isPricingContextInitialized()
      if (initialized) {
        updatePricingContextData()
      }
    }

    const updatePricingContext = () => {
      setPricingContext(getPricingContext())
    }

    const updateVatInclusivity = () => {
      setVatIncl(getPricingContextVatInclusivity())
    }

    if (developmentMode) {
      initializePricingContext(tenant, market, handleInitializedEvent)
    } else if (!isPricingContextInitialized()) {
      document.addEventListener(
        pricingContextModuleHelpers.EVENTS.INITIALIZED_EVENT,
        handleInitializedEvent
      )
    } else {
      updatePricingContextData()
    }

    document.addEventListener(
      pricingContextModuleHelpers.EVENTS.VAT_INCLUSIVITY_CHANGE_EVENT,
      updateVatInclusivity
    )

    document.addEventListener(
      pricingContextModuleHelpers.EVENTS.CONTEXT_CHANGE_EVENT,
      updatePricingContext
    )

    return () => {
      if (!isPricingContextInitialized()) {
        document.removeEventListener(
          pricingContextModuleHelpers.EVENTS.INITIALIZED_EVENT,
          handleInitializedEvent
        )
      }

      document.removeEventListener(
        pricingContextModuleHelpers.EVENTS.VAT_INCLUSIVITY_CHANGE_EVENT,
        updateVatInclusivity
      )

      document.removeEventListener(
        pricingContextModuleHelpers.EVENTS.CONTEXT_CHANGE_EVENT,
        updatePricingContext
      )
    }
  }, [tenant, market, setPricingContext, setVatIncl, developmentMode])
}
