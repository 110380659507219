import React, { ReactNode } from 'react'
import { ClickableStyledIcon, FlexButton, TextCursorSpan, WordBreakAllTypography } from '../../styledComponents/couponComponent.styles'

import { useScopedBaseConfig } from '../../contexts/CouponCalloutConfig.context'
import { useLocalization } from '../../hooks/useLocalizations'
import { useCouponDetailsStore } from '../../store/couponDetails.store'
import { CouponCodePopover } from './CouponCodePopover'

interface CouponCodeProps {
  queryKey: string,
  children?: ReactNode
}

export const CouponCode = ({
  queryKey
}: CouponCodeProps) => {
  const { t } = useLocalization()
  const { pageId } = useScopedBaseConfig()
  const productCouponDetails = useCouponDetailsStore((state) =>
    state.data[pageId]?.couponDetails?.productCouponData?.[queryKey])

  if (!productCouponDetails) {
    return null
  }

  return (
    <WordBreakAllTypography>
      {t('coupon-callout.coupon-code')}:
      <TextCursorSpan>{productCouponDetails?.couponCode}</TextCursorSpan>
      <CouponCodePopover
        queryKey={queryKey}
      >
        <FlexButton skin='unstyled'>
          <ClickableStyledIcon iconType='info' size='16p' alt={t('coupon-callout.info-icon')} />
        </FlexButton>
      </CouponCodePopover>
    </WordBreakAllTypography>
  )
}
