import React, { ReactNode } from 'react'

import { ErrorBoundary } from '@vp/swan'

interface CouponErrorBoundaryProps {
  children: ReactNode;
}

const CouponErrorBoundary = ({ children }: CouponErrorBoundaryProps) => {
  return <ErrorBoundary fallback={<></>}>{children}</ErrorBoundary>
}

export { CouponErrorBoundary }
