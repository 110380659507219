import { initialize, isFeatureEnabled, whenAvailable } from '@vp/ab-reader'
import { useCallback, useEffect, useState } from 'react'

export const useFeatureToggle = (): { isFeatureFlagEnabled: (featureFlag: string) => boolean } => {
  const [isClientAvailable, setIsClientAvailable] = useState(false)

  useEffect(() => {
    const fetchFeatureTogglesClient = async () => {
      initialize()
      whenAvailable((available) => {
        if (available) {
          setIsClientAvailable(true)
        }
      }, 3000)
    }

    fetchFeatureTogglesClient()
  }, [])

  const isFeatureFlagEnabled = useCallback(
    (featureFlag: string): boolean => {
      return isClientAvailable ? isFeatureEnabled(featureFlag) : false
    },
    [isClientAvailable]
  )

  return { isFeatureFlagEnabled }
}
