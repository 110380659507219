import { FlexBox, Typography } from '@vp/swan'
import React, { ReactNode } from 'react'

import { RawPrice } from '@vp/vp-tokenized-fragment'
import { useScopedBaseConfig } from '../../contexts/CouponCalloutConfig.context'
import { useLocalization } from '../../hooks/useLocalizations'
import { useCouponDetailsStore } from '../../store/couponDetails.store'
import { PromoBar } from '../../styledComponents/couponComponent.styles'
import { CouponCode } from './CouponCode'

interface PriceDisplayMessageProps {
  queryKey: string;
  asLowAsPrice?: boolean;
  children?: ReactNode;
}

const PriceDisplayMessage = ({ queryKey, asLowAsPrice }: PriceDisplayMessageProps) => {
  const { t } = useLocalization()
  const { pageId, locale, tenant } = useScopedBaseConfig()
  const vatInclusivity = useCouponDetailsStore((state) => state.vatIncl)

  const couponDetails = useCouponDetailsStore((state) => state.data[pageId]?.couponDetails)

  const productCouponDetails = couponDetails?.productCouponData[queryKey]
  const productCouponPrice = productCouponDetails?.prices
  const currency = couponDetails?.currency ?? 'USD'
  const fractionDigits = couponDetails?.fractionDigits ?? 2

  if (!productCouponDetails || !productCouponPrice) {
    return null
  }

  const getPricingInfo = () => {
    const priceToBeConsidered = asLowAsPrice
      ? productCouponPrice.unitDiscountedPrice
      : productCouponPrice.discountedPrice
    const taxedOrUntaxed = vatInclusivity ? 'taxed' : 'untaxed'

    const minimumFractionDigits = priceToBeConsidered?.taxed % 1 === 0 ? 0 : fractionDigits

    return {
      listPrice: priceToBeConsidered[taxedOrUntaxed],
      discountPrice: priceToBeConsidered[taxedOrUntaxed],
      minimumFractionDigits,
      currency,
      culture: locale,
      vatInc: vatInclusivity,
      hideVatMessage: false,
    }
  }

  return (
    <PromoBar>
      <FlexBox flexWrap='wrap' textAlign='left' flexDirection='row'>
        <Typography fontWeight='normal' margin={0}>
          {t('coupon-callout.coupon-message-with-price')}{' '}
          <RawPrice pricingInfo={getPricingInfo()} tenant={tenant} placeHolderText='' />
          {' | '}
        </Typography>
        &nbsp;
        <Typography>
          <CouponCode queryKey={queryKey} />
        </Typography>
      </FlexBox>
    </PromoBar>
  )
}

export { PriceDisplayMessage }
