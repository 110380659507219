export enum PageId {
  Home = 'Home',
  PLP = 'PLP',
  Search = 'Search',
  Category = 'Category',
  PDC = 'PDC',
  PDP = 'PDP',
  LATPDP = 'LATPDP',
  Gallery = 'Gallery',
  Recommendation = 'Recommendation',
  LaunchMyBusiness = 'LaunchMyBusiness',
  UnknownPage = '',
}

export const fullWidthInclusivePages:PageId[] = [
  PageId.PDC
]
