import {
  Box,
  Button,
  FlexBox,
  Popover,
  PopoverCloser,
  PopoverContent,
  PopoverLauncher,
  Typography,
} from '@vp/swan'
import React, { ReactNode } from 'react'

import { TokenReplace } from '@vp/vp-tokenized-fragment'
import styled from 'styled-components'
import { useScopedBaseConfig } from '../../contexts/CouponCalloutConfig.context'
import { useLocalization } from '../../hooks/useLocalizations'
import { useCouponDetailsStore } from '../../store/couponDetails.store'
import { ClickableStyledIcon } from '../../styledComponents/couponComponent.styles'

interface CouponCodePopoverProps {
  queryKey: string;
  children?: ReactNode;
}

const StyledPopoverLauncher = styled(PopoverLauncher)`
  display: flex !important;
  align-items: center;
`

const CouponCodePopover = ({ queryKey, children }: CouponCodePopoverProps) => {
  const { t } = useLocalization()
  const { locale, pageId } = useScopedBaseConfig()
  const productCouponDetails = useCouponDetailsStore((state) =>
    state.data[pageId]?.couponDetails?.productCouponData?.[queryKey])

  if (!locale || !productCouponDetails) {
    return null
  }

  return (
    <Popover aria-label={t('coupon-callout.promo-code')}>
      <StyledPopoverLauncher>{children}</StyledPopoverLauncher>
      <PopoverContent aria-label={t('coupon-callout.coupon-callout-popover')} dialog>
        <FlexBox gap='5'>
          <Box>
            <Typography fontWeight='bold' fontSize='small'>
              {productCouponDetails.messaging.displayMessage}&nbsp;
            </Typography>
            <Typography fontSize='small'>
              {productCouponDetails.messaging.staticLegalCopy}
            </Typography>
            <Typography fontSkin='footnote' textColor='subtle'>
              {productCouponDetails.expiresAt && (
                <TokenReplace
                  tokenizedText={t('coupon-callout.coupon-expiry-message')}
                  contextVariables={{
                    Date: new Intl.DateTimeFormat(locale, {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    }).format(new Date(productCouponDetails.expiresAt)),
                  }}
                />
              )}
            </Typography>
          </Box>
          <PopoverCloser>
            <Button skin='unstyled'>
              <ClickableStyledIcon iconType='close' alt={t('coupon-callout.cancel-icon')} />
            </Button>
          </PopoverCloser>
        </FlexBox>
      </PopoverContent>
    </Popover>
  )
}

export { CouponCodePopover }
