import { CharacterRestrictedEllipsedTypography, LineRestrictedEllipsedTypography, PromoBar } from '../../styledComponents/couponComponent.styles'

import { FlexBox, } from '@vp/swan'
import React from 'react'
import { fullWidthInclusivePages } from '../../constants/pageId'
import { useScopedBaseConfig } from '../../contexts/CouponCalloutConfig.context'
import { useCouponDetailsStore } from '../../store/couponDetails.store'
import { CouponCalloutDisplayProps } from './CouponCalloutDisplay'
import { CouponCode } from './CouponCode'

const PMTDisplayMessage = ({ queryKey }: CouponCalloutDisplayProps) => {
  const { pageId } = useScopedBaseConfig()
  const fullWidth = fullWidthInclusivePages.includes(pageId)
  const productCouponDetails = useCouponDetailsStore((state) =>
    state.data[pageId]?.couponDetails?.productCouponData?.[queryKey])

  const ElippsedTypographyComponent = fullWidth
    ? CharacterRestrictedEllipsedTypography
    : LineRestrictedEllipsedTypography

  const renderDisplayMsgForCoupon = (
    <ElippsedTypographyComponent fontWeight='normal' margin={0}>
      {productCouponDetails?.messaging.displayMessage}&nbsp;
    </ElippsedTypographyComponent>
  )

  return (
    <PromoBar>
      <FlexBox
        flexWrap='wrap'
        textAlign='left'
        flexDirection='row'
      >
        {renderDisplayMsgForCoupon}
        <CouponCode queryKey={queryKey} />
      </FlexBox>
    </PromoBar>
  )
}

export { PMTDisplayMessage }
