import { resources } from '.'
import { DEFAULT_LOCALE } from '../constants/locale'

interface NestedStringObject {
  [key: string]: string | NestedStringObject
}

export const deepMerge = (target: NestedStringObject, source: NestedStringObject): NestedStringObject => {
  const result = { ...target }
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      result[key] = typeof source[key] === 'object' && source[key] !== null
        ? deepMerge(result[key] as NestedStringObject ?? {}, source[key])
        : source[key]
    }
  }
  return result
}

export const getLocalizationsFor = (locale: string) => {
  const resourcesObj = resources[locale.toLowerCase()]
  if (!resourcesObj) {
    return resources[DEFAULT_LOCALE.toLowerCase()]
  }
  return deepMerge(resources[DEFAULT_LOCALE.toLowerCase()], resourcesObj)
}
