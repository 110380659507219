import pricingContextModuleHelpers from '@vp/pricing-context-module/helpers'

const getPricingContext = () => {
  const pricingContext = pricingContextModuleHelpers.getPcxtV3()?.getPricingContext()
  return pricingContext ?? {}
}

const isPricingContextInitialized = (): boolean => {
  return pricingContextModuleHelpers.getPcxtV3()?.isInitialized() ?? false
}

const getPricingContextVatInclusivity = () => {
  const isVatInclusive = pricingContextModuleHelpers.getPcxtV3()?.isVatInclusive()
  return isVatInclusive ?? false
}

const initializePricingContext = (tenant: string, market: string, callback: () => any) => {
  try {
    pricingContextModuleHelpers.initializePricingContext({
      merchantId: tenant,
      market,
      isDevelopmentMode: true,
    })

    document.addEventListener(
      pricingContextModuleHelpers.EVENTS.INITIALIZED_EVENT,
      callback
    )
  } catch (error) {
    console.error('Failed to initialize pricing context:', error)
  }
}

const setCouponCode = async (couponCode: string) => {
  try {
    await pricingContextModuleHelpers.getPcxtV3()?.setCouponCode(couponCode)
  } catch (error) {
    console.error('Failed to set coupon code:', error)
  }
}

export { getPricingContext, getPricingContextVatInclusivity, initializePricingContext, isPricingContextInitialized, setCouponCode }
