import { SkeletonText, SkeletonTextLine } from '@vp/swan'
import React, { useEffect } from 'react'

import { useScopedBaseConfig } from '../../contexts/CouponCalloutConfig.context'
import { LocalizationsProvider } from '../../hooks/useLocalizations'
import { setVersionOnWindow } from '../../hooks/useSetVersionOnWindow'
import { useCouponDetailsStore } from '../../store/couponDetails.store'
import { getQueryKey } from '../../utils/generic.util'
import { CouponCalloutProps } from '../CouponCallout/CouponCallout'
import { CouponCalloutDisplay } from '../CouponDisplay/CouponCalloutDisplay'

const CouponCalloutExtractionWrapper: React.FC<CouponCalloutProps> = (props: CouponCalloutProps) => {
  const { mpvId, productId, version, quantity, selections, asLowAsPrice } = props

  const addQuery = useCouponDetailsStore((state) => state.addQuery)
  const baseConfig = useScopedBaseConfig()
  const isPricingContextInitialized = useCouponDetailsStore((state) => state.pricingContext?.isInitialized)
  const localizations = useCouponDetailsStore((state) => state.localizations)

  const { pageId } = baseConfig

  setVersionOnWindow()
  useEffect(() => {
    if (isPricingContextInitialized) {
      if (!mpvId && !productId) {
        throw new Error('Missing mpvId or productId in query')
      } else
        if (productId && !version) {
          throw new Error('Missing version in query')
        } else if (mpvId) {
          addQuery({ mpvId, quantity, selections }, baseConfig)
        } else {
          addQuery({ productId, version, quantity, selections }, baseConfig)
        }
    }
  }, [mpvId, productId, version, quantity, selections, isPricingContextInitialized, addQuery])

  // Generating a unique key for the current price query using provided parameters
  let queryKey = ''
  if (mpvId) {
    queryKey = getQueryKey({ mpvId, quantity, selections }) || ''
  } else if (productId && version) {
    queryKey = getQueryKey({ productId, version, quantity, selections }) || ''
  }

  const productCouponDetails = useCouponDetailsStore((state) =>
    state.data[pageId]?.couponDetails?.productCouponData?.[queryKey])

  const transitionedQueryMap = useCouponDetailsStore((state) =>
    state.data[pageId]?.transitionedQueryMap)

  const isTransitionedQueryMapEmpty = transitionedQueryMap ? Object.keys(transitionedQueryMap).length === 0 : true

  if (!isTransitionedQueryMapEmpty || !isPricingContextInitialized || !baseConfig) {
    return (
      <SkeletonText>
        <SkeletonTextLine width='narrow' />
      </SkeletonText>
    )
  }

  if (isTransitionedQueryMapEmpty && !productCouponDetails) {
    return null
  }

  return (
    <LocalizationsProvider localizations={localizations}>
      <CouponCalloutDisplay
        queryKey={queryKey}
        asLowAsPrice={asLowAsPrice}
      />
    </LocalizationsProvider>
  )
}

export { CouponCalloutExtractionWrapper }
