import { COUPON_COMP_INCLUSIVE_LOCALES, Locale } from '../constants/locale'

export const isLocaleEnabled = (l: string) => {
  const locale = toLocale(l)
  return COUPON_COMP_INCLUSIVE_LOCALES.includes(locale)
}

export const toLocale = (l: string): Locale => {
  try {
    const parts = l.split('-')
    return `${parts[0].toLowerCase()}-${parts[1].toUpperCase()}` as Locale
  } catch (e) {
    return 'xx-YY' as Locale
  }
}

export const getMarketFromLocale = (locale: Locale): string | undefined => {
  try {
    const parts = locale.split('-')
    return `${parts[1].toUpperCase()}`
  } catch (e) {
    return undefined
  }
}
