import { Query } from '../store/couponDetails.types'

// TODO: We removed the pageId dependency here - check impact
export const getQueryKey = (query: Query): string => {
  if (typeof query !== 'object' || query === null) {
    throw new Error('Input must be a non-null object')
  }

  const parts: string[] = []
  const serialize: (value: string | number | boolean | object | Array<string | number> | undefined) => string = (value) => {
    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      return String(value)
    } else if (Array.isArray(value)) {
      return value.map(item => String(item)).join('-')
    } else if (typeof value === 'object' && value !== null) {
      return Object.keys(value).sort().map(key => `${key}:${serialize((value as { [key: string]: any })[key])}`).join('::')
    } else {
      return String(value)
    }
  }

  const queryKeys = Object.keys(query).sort()

  queryKeys.forEach(key => {
    const value = query[key as keyof Query]
    if (value === undefined || (typeof value === 'object' && value !== null && Object.keys(value).length === 0)) return
    parts.push(`${key}:${serialize(query[key as keyof Query])}`)
  })

  return parts.sort().join('::')
}
