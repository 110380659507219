import React, { createContext, useContext, useEffect } from 'react'

import { BaseConfig } from '../store/couponDetails.types'
import { CSRWrapper } from '../components/CSRWrapper/CSRWrapper'
import { CouponErrorBoundary } from '../components/CouponErrorBoundary/CouponErrorBoundary'
import { getLocalizationsFor } from '../localization/getLocalizations'
import { getMarketFromLocale } from '../utils/locale.util'
import { useCouponDetailsStore } from '../store/couponDetails.store'
import { useGetCouponCode } from '../hooks/useGetCouponCode'
import { usePricingContext } from '../hooks/usePricingContextInitialization'

const CouponCallout = createContext<BaseConfig>({} as BaseConfig)
const CouponCalloutProvider = ({ baseConfig, children }: { baseConfig: BaseConfig, children: any }) => {
  const { tenant, locale, developmentMode, pageId } = baseConfig
  if (!tenant || !locale || !pageId) {
    throw new Error('Missing tenant, locale or pageId in baseConfig')
  }

  const market = getMarketFromLocale(locale) as string
  usePricingContext(tenant, market, developmentMode)

  const initializeScopedData = useCouponDetailsStore((state) => state.initializeScopedData)
  initializeScopedData(pageId)

  const setLocalizations = useCouponDetailsStore((state) => state.setLocalizations)

  useEffect(() => {
    const localizations = getLocalizationsFor(locale)
    setLocalizations(localizations)
  }, [locale])

  const couponCode = useGetCouponCode()
  const setCouponCode = useCouponDetailsStore((state) => state.setCouponCode)
  useEffect(() => {
    if (couponCode) {
      setCouponCode(couponCode)
    }
  }, [couponCode])
  return (
    <CSRWrapper>
      <CouponErrorBoundary>
        <CouponCallout.Provider value={baseConfig}>
          {children}
        </CouponCallout.Provider>
      </CouponErrorBoundary>
    </CSRWrapper>

  )
}

const useScopedBaseConfig = () => {
  return useContext(CouponCallout)
}

export { CouponCalloutProvider, useScopedBaseConfig }
