import { messageType as MessageType } from '../../clients/couponCallout.client.types'
import { AppliedCouponStyledIcon, AppliedPromoComponent, AppliedStatePromoBar, ExtendedAppliedCouponStyledIcon, FlexButton, TextCursorSpan } from '../../styledComponents/couponComponent.styles'

import React from 'react'
import { useScopedBaseConfig } from '../../contexts/CouponCalloutConfig.context'
import { useLocalization } from '../../hooks/useLocalizations'
import { useCouponDetailsStore } from '../../store/couponDetails.store'
import { CouponCalloutDisplayProps } from './CouponCalloutDisplay'
import { CouponCodePopover } from './CouponCodePopover'

/*
Use cases for applied state
1. Cart Coupon - Should display coupon in applied state (no price is present for Cart Coupon)
2. Product Coupon - Should display coupon in applied state when the discounted price is less than the list price
3. Should render the display message when the coupon is cart and pages are among PDP, LATPDP and Gallery
4. Should not display combinable message
*/

const AppliedCouponDisplay = ({ queryKey }: CouponCalloutDisplayProps) => {
  const { t } = useLocalization()
  const { pageId } = useScopedBaseConfig()
  const productCouponDetails = useCouponDetailsStore((state) =>
    state.data[pageId]?.couponDetails?.productCouponData?.[queryKey])

  if (!productCouponDetails) return null
  const { prices, messageType, couponCode } = productCouponDetails

  if ((messageType === MessageType.PRICES && !prices) || (prices && prices.discountedPrice.taxed >= prices.listPrice.taxed)) {
    return null
  }

  return (
    <AppliedStatePromoBar>
      <AppliedPromoComponent>
        <AppliedCouponStyledIcon iconType='check' size='16p' alt={t('coupon-callout.check-icon')} />
        <TextCursorSpan>{couponCode}</TextCursorSpan>
        <CouponCodePopover
          queryKey={queryKey}
        >
          <FlexButton skin='unstyled'>
            <ExtendedAppliedCouponStyledIcon
              iconType='info'
              size='16p'
              alt={t('coupon-callout.info-icon')}
            />
          </FlexButton>
        </CouponCodePopover>
      </AppliedPromoComponent>
    </AppliedStatePromoBar>

  )
}

export { AppliedCouponDisplay }
