import {
  Button,
  ButtonProps,
  FlexBox,
  FlexBoxProps,
  Icon,
  IconProps,
  Span,
  SpanProps,
  Typography,
  TypographyProps
} from '@vp/swan'

import styled from 'styled-components'

export const TextCursorSpan: React.ComponentType<SpanProps> = styled(Span)`
  cursor: text;
  font-weight: bold;
`

export const FlexButton: React.ComponentType<ButtonProps> = styled(Button)`
  display: flex !important;
`

// icon for pricing context coupon
export const AppliedCouponStyledIcon: React.ComponentType<IconProps> = styled(Icon)`
  background-color: var(--swan-sem-color-bg-standard) !important;
`

// pricing context coupon component styles
export const AppliedPromoComponent: React.ComponentType<FlexBoxProps> = styled(FlexBox)`
  background-color: var(--swan-sem-color-bg-promo-strong-paired);
  border-radius: var(--swan-sem-border-radius-standard);
  align-items: center;
  justify-content: center;
  padding: 4px 8px 4px 8px;
  color: var(--swan-sem-color-text-promo-strong-paired);
  gap: var(--swan-sem-space-2);
  flex-shrink: 0;  
  max-width: fit-content;
`

export const ExtendedAppliedCouponStyledIcon: React.ComponentType<IconProps> = styled(
  AppliedCouponStyledIcon
)`
  pointer-events: none;
`

export const ClickableStyledIcon: React.ComponentType<IconProps> = styled(Icon)`
  pointer-events: none;
`

export const CharacterRestrictedEllipsedTypography: React.ComponentType<TypographyProps> = styled(
  Typography
)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 28ch;
`

export const WordBreakAllTypography: React.ComponentType<FlexBoxProps> = styled(FlexBox)` 
  flex-wrap: wrap;
  align-items: center;
  gap: var(--swan-sem-space-2);
`

export const LineRestrictedEllipsedTypography: React.ComponentType<TypographyProps> = styled(
  Typography
)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const AppliedStatePromoBar = styled(FlexBox).attrs({
  fontWeight: 'bold',
  fontSize: 'small',
  px: 0,
  py: 0,
  style: { minHeight: 60 },
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '2',
})`
  flex-direction: column !important;
  min-height: fit-content !important;
  width: fit-content;
  color: var(--swan-sem-color-text-standard);
  margin-top: var(--swan-sem-space-3);
  margin-bottom: var(--swan-sem-space-3);
  background-color: var(--sem-color-bg-none); // No background color for applied state
  border-radius: none; // No border radius for applied state
`
export const PromoBar = styled(FlexBox).attrs({
  fontWeight: 'bold',
  fontSize: 'small',
  px: 3,
  py: 2,
  style: { minHeight: 60 },
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '2',
})`
  flex-direction: column !important;
  min-height: fit-content !important;
  width: fit-content;
  color: var(--swan-sem-color-text-standard);
  margin-top: var(--swan-sem-space-3);
  margin-bottom: var(--swan-sem-space-3);
  border-radius: var(--swan-sem-border-radius-standard);
  background-color: var(--swan-sem-color-bg-promo);
`
