import React, { ReactNode } from 'react'
import { CouponType, messageType } from '../../clients/couponCallout.client.types'

import { useScopedBaseConfig } from '../../contexts/CouponCalloutConfig.context'
import { useCouponDetailsStore } from '../../store/couponDetails.store'
import { AppliedCouponDisplay } from './AppliedCouponDisplay'
import { PMTDisplayMessage } from './PMTDisplayMessage'
import { PriceDisplayMessage } from './PriceDisplayMessage'

export interface CouponCalloutDisplayProps {
  queryKey: string;
  children?: ReactNode;
  asLowAsPrice?: boolean
}

// It prioritizes the applied coupon state first, followed by the messageType of the coupon details.
const CouponCalloutDisplay: React.FC<CouponCalloutDisplayProps> = ({
  queryKey, asLowAsPrice
}: CouponCalloutDisplayProps) => {
  const { pageId } = useScopedBaseConfig()
  const productCouponDetails = useCouponDetailsStore((state) =>
    state.data[pageId]?.couponDetails?.productCouponData?.[queryKey])
  const pricingContext = useCouponDetailsStore((state) => state.pricingContext)
  const isAppliedStateCoupon = !!pricingContext?.value?.couponCode

  if (isAppliedStateCoupon) {
    return <AppliedCouponDisplay queryKey={queryKey} />
  }

  if (
    productCouponDetails?.couponType === CouponType.Product &&
      !productCouponDetails?.isCouponPriceBetterThanOffer
  ) {
    return null
  }

  switch (productCouponDetails?.messageType) {
    case messageType.PRICES:
      return <PriceDisplayMessage queryKey={queryKey} asLowAsPrice={asLowAsPrice} />
    default:
      return <PMTDisplayMessage queryKey={queryKey} />
  }
}

export { CouponCalloutDisplay }
