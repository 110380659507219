import { CouponDetails, CouponPricingRequest } from './couponCallout.client.types'

import { COUPON_CALLOUTS_BACKEND_URL } from '../constants/api.constants'

export const fetchCouponDetails = async (
  request: CouponPricingRequest
): Promise<CouponDetails> => {
  const url = `${COUPON_CALLOUTS_BACKEND_URL}/tenant/${request.tenant}/locale/${request.locale}/coupon-price?requestor=${request.requestor}&pageId=${request.pageId}`

  const { tenant, locale, pageId, requestor, ...body } = request

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  const responseData = await response.json()

  if (!response.ok) {
    const errorPayload = { ...responseData as {}, status: response.status }
    throw new Error(JSON.stringify(errorPayload))
  }

  return responseData as CouponDetails
}
