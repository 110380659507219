import { CouponDetails, CouponPricingRequest } from '../clients/couponCallout.client.types'
import { DEBOUNCE_TIME } from '../constants/api.constants'
import { BaseConfig, CouponDetailsStoreData, CouponsDetailsStore, PricingContext, Query } from './couponDetails.types'

import { ensureUserTestId } from '@vp/ab-test-cookie'
import { debounce } from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { fetchCouponDetails } from '../clients/couponCallout.client'
import { PageId } from '../constants/pageId'
import { getQueryKey } from '../utils/generic.util'

const debounceFunctionCache: Record<string, any> = {}

const getEmptyScopedData = (): CouponDetailsStoreData => ({
  couponDetails: {} as CouponDetails,
  queryMap: {},
  transitionedQueryMap: {}
})

const setScopedDebouncedCouponDetails = (baseConfig: BaseConfig, getStore: () => CouponsDetailsStore, setStore: (update: Partial<CouponsDetailsStore>) => void) => {
  const scope = baseConfig.pageId
  if (!debounceFunctionCache[scope]) {
    debounceFunctionCache[scope] = debounce(async (getStore: () => CouponsDetailsStore, setStore : (update: Partial<CouponsDetailsStore>) => void): Promise<void> => {
      const { queryMap } = getStore().data[scope]
      const { pricingContext, couponCode } = getStore()
      const { locale, tenant, pageId } = baseConfig
      const queries: Query[] = Object.values(queryMap) as Query[]

      if (!tenant || !locale || queries.length === 0) return
      try {
        setScopedData(getStore, setStore, scope, 'transitionedQueryMap', { ...queryMap })
        setScopedData(getStore, setStore, scope, 'queryMap', {})

        const testUserId = ensureUserTestId()
        const request: CouponPricingRequest = {
          tenant,
          locale,
          pageId,
          requestor: 'paa-coupon-component-' + pageId,
          couponCode,
          testUserId,
          productData: queries,
          pricingContext: pricingContext?.value,
        }
        const couponDetails = await fetchCouponDetails(request)

        setScopedData(
          getStore,
          setStore,
          scope,
          'couponDetails',
          {
            productCouponData: {
              ...getStore().data[scope].couponDetails.productCouponData,
              ...couponDetails.productCouponData,
            },
            ...Object.keys(couponDetails).reduce((acc, key) => {
              if (key !== 'productCouponData') {
                acc[key as keyof CouponDetails] = couponDetails[key as keyof CouponDetails] ?? getStore().data[scope].couponDetails[key as keyof CouponDetails] as any
              }
              return acc
            }, {} as Partial<CouponDetails>)
          }
        )
        setScopedData(getStore, setStore, scope, 'transitionedQueryMap', {})
      } catch (error) {
        setScopedData(getStore, setStore, scope, 'transitionedQueryMap', {})
        console.error('Error fetching coupon details for queries:', queries, error)
      }
    }, /* DEBOUNCE_PAGEID_MAPPING[]  || */ DEBOUNCE_TIME) // TODO: Page id based debounce time - dev was done.
  }
  return debounceFunctionCache[scope](getStore, setStore)
}

export const useCouponDetailsStore = create<CouponsDetailsStore>()(
  devtools(
    (set, get) => ({
      data: {
        [PageId.UnknownPage]: getEmptyScopedData()
      },
      setCouponCode: (couponCode: string) => {
        set({ couponCode })
      },
      setVatIncl: (vatIncl: boolean) => {
        set({ vatIncl })
      },
      setLocalizations: (localizations: any) => {
        set({ localizations })
      },
      addQuery: async (query: Query, baseConfig: BaseConfig): Promise<void> => {
        const scope = baseConfig.pageId
        if (!scope) return
        if (!(query.mpvId || (query.productId && query.version))) {
          return
        }

        const queryId = getQueryKey(query)

        if (get().data[scope].queryMap[queryId] ||
          get().data[scope].couponDetails?.productCouponData?.[queryId] ||
          get().data[scope].transitionedQueryMap[queryId]) {
          return
        }
        query.productVariantId = queryId

        const existingQuery = get().data[scope].queryMap

        // TODO: We should use partial - but was not working - check how can that be fixed
        setScopedData(get, set, scope, 'queryMap', { ...existingQuery, [queryId]: query })
        setScopedDebouncedCouponDetails(baseConfig, get, set)
      },
      setPricingContext: (pricingContext: PricingContext) => {
        set({ pricingContext: { value: pricingContext, isInitialized: true } })
      },
      initializeScopedData: (scope: string) => {
        const existingData = get().data
        if (!existingData[scope]) {
          set({ data: { ...existingData, [scope]: getEmptyScopedData() } })
        }
      }
    }))
)

const setScopedData = (get: any, set: any, scope: string, fieldName: string, value: any) => {
  const existingData = get().data
  set({ data: { ...existingData, [scope]: { ...existingData[scope], [fieldName]: value } } })
}
