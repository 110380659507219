import { EXPIRES_3DAYS } from '../constants/api.constants'
import upReader from '@vp/up-reader'
import { useCouponDetailsStore } from '../store/couponDetails.store'

/**
 * Custom hook to get the coupon code based on priority:
 * 1. From pricing context
 * 2. From URL
 * 3. From landing coupon (upReader) and local storage
 */
export const useGetCouponCode = () => {
  const pricingContext = useCouponDetailsStore((state) => state.pricingContext)
  const pricingContextCouponCode = pricingContext?.value?.couponCode

  if (pricingContextCouponCode) {
    return pricingContextCouponCode
  }

  const couponCodeFromURL = getCouponCodeFromUrl()
  if (couponCodeFromURL) {
    setCachedCouponInfo(couponCodeFromURL)
    return couponCodeFromURL
  }

  const userContextCouponCode = validateAndFetchUserContextCoupon()
  if (userContextCouponCode) {
    setCachedCouponInfo(userContextCouponCode)
    return userContextCouponCode
  }

  return ''
}

const getCouponCodeFromUrl = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('couponCode') || params.get('couponcode')
}

const getCouponCodeFromUpReader = () => {
  return upReader.getTrait('couponCode') || upReader.getTrait('couponcode')
}

const isCouponWithinTimeLimit = (couponCodeTimestamp: number) => {
  return Date.now() - couponCodeTimestamp < EXPIRES_3DAYS
}

const getCachedCouponInfo = () => {
  try {
    return JSON.parse(localStorage.getItem('landingCouponInfo') || '{}')
  } catch (e) {
    console.error('Error parsing couponInfo from localStorage', e)
    return {}
  }
}

const setCachedCouponInfo = (couponCode: string) => {
  return localStorage.setItem(
    'landingCouponInfo',
    JSON.stringify({
      couponCode,
      couponCodeTime: Date.now().toString(),
    })
  )
}

const validateAndFetchUserContextCoupon = (): string | undefined => {
  const userContextCouponCode = getCouponCodeFromUpReader()
  const userContextCouponTimestamp = new Date(upReader.getTrait('couponCodeTime')).getTime()
  const { couponCode: cachedCouponCode, couponCodeTime: cachedCouponTimestamp } =
    getCachedCouponInfo()

  if (userContextCouponCode) {
    const isFreshCoupon = !cachedCouponCode || userContextCouponCode !== cachedCouponCode

    if (isFreshCoupon && isCouponWithinTimeLimit(userContextCouponTimestamp)) {
      return userContextCouponCode
    }

    if (userContextCouponCode === cachedCouponCode && (
      isCouponWithinTimeLimit(userContextCouponTimestamp) ||
        isCouponWithinTimeLimit(Number(cachedCouponTimestamp))
    )) {
      return userContextCouponCode
    }
  }

  return undefined
}
