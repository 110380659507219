import { PricingContext, Query } from '../store/couponDetails.types'

import { Experiment } from '@vp/ab-reader/lib/types/experiment'

export interface ExperimentData {
  experimentKey: string;
  variationKey: string;
}

export interface CouponPricingRequest {
  tenant: string,
  locale: string,
  pageId: string,
  requestor: string,
  effectiveDateTime?: string,
  experimentsData?: Experiment[];
  productData: Query[];
  couponCode?: string;
  testUserId?: string;
  pricingContext?: PricingContext
}

export interface CouponDetails {
  currency?: string;
  fractionDigits?: number;
  productCouponData: {
    [productVariantId: string]: ProductCouponInfo; // this being queryId
  };
}

export interface ProductCouponInfo {
  couponCode: string; // input coupon code || coupon code from another data source(Direct to site sources),
  couponType: CouponType; // Product coupon || cart coupon,
  expiresAt: string;
  doesOfferExist: boolean;
  isCouponPriceBetterThanOffer: boolean; // if coupon is applicable for this product,
  prices?: ProductCouponPricing;
  messaging: ProductCouponMessaging;
  messageType: messageType;
}

export enum messageType {
  CUSTOM = 'CUSTOM_MESSAGE',
  PMT = 'PMT_MESSAGE',
  PRICES = 'PRICE_MESSAGE',
}

export enum CouponType {
  Cart = 'Cart',
  Product = 'Product',
}

export interface ProductCouponPricing {
  listPrice: Price;
  discountedPrice: Price;
  unitListPrice: Price;
  unitDiscountedPrice: Price;
}

export interface Price {
  taxed: number;
  untaxed: number;
}

export interface ProductCouponMessaging {
  displayMessage?: string;
  detailsMessage?: string;
  staticLegalCopy?: string;
  customMessage?: string;
}
