import React from 'react'
import { COUPON_CALLOUT_COMPONENT_FOR_EU_AND_ANZS } from '../../constants/featureFlags'
import { useScopedBaseConfig } from '../../contexts/CouponCalloutConfig.context'
import { useFeatureToggle } from '../../hooks/useFeatureToggle'
import { isLocaleEnabled } from '../../utils/locale.util'
import { CSRWrapper } from '../CSRWrapper/CSRWrapper'
import { CouponCalloutExtractionWrapper } from '../CouponCalloutExtraction/CouponCalloutExtractionWrapper'
import { CouponErrorBoundary } from '../CouponErrorBoundary/CouponErrorBoundary'

interface CouponCalloutProps {
  mpvId?: string;
  productId?: string;
  version?: number;
  asLowAsPrice?: boolean;
  quantity?: number;
  selections?: Record<string, string> | undefined;
}

const CouponCallout = (props: CouponCalloutProps) => {
  const { locale } = useScopedBaseConfig()
  const { isFeatureFlagEnabled } = useFeatureToggle()

  if (isLocaleEnabled(locale) || isFeatureFlagEnabled(COUPON_CALLOUT_COMPONENT_FOR_EU_AND_ANZS)) {
    return (
      <CSRWrapper>
        <CouponErrorBoundary>
          <CouponCalloutExtractionWrapper {...props} />
        </CouponErrorBoundary>
      </CSRWrapper>
    )
  }
  return null
}

export { CouponCallout }
export type { CouponCalloutProps }
