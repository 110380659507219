import dadk from './texts/da-dk.json'
import dede from './texts/de-de.json'
import engb from './texts/en-gb.json'
import enie from './texts/en-ie.json'
import enus from './texts/en-us.json'
import eses from './texts/es-es.json'
import esus from './texts/es-us.json'
import fifi from './texts/fi-fi.json'
import frca from './texts/fr-ca.json'
import frfr from './texts/fr-fr.json'
import itit from './texts/it-it.json'
import nbno from './texts/nb-no.json'
import nlnl from './texts/nl-nl.json'
import ptpt from './texts/pt-pt.json'
import svse from './texts/sv-se.json'

type TranslationMap = typeof esus

export const resources: Record<string, TranslationMap> = {
  'da-dk': dadk,
  'de-at': dede,
  'de-ch': dede,
  'de-de': dede,
  'en-au': enus,
  'en-ca': enus,
  'en-gb': engb,
  'en-ie': enie,
  'en-in': enus,
  'en-nz': enus,
  'en-sg': enus,
  'en-us': enus,
  'es-es': eses,
  'es-us': esus,
  'fi-fi': fifi,
  'fr-be': frfr,
  'fr-ca': frca,
  'fr-ch': frfr,
  'fr-fr': frfr,
  'it-ch': itit,
  'it-it': itit,
  'nb-no': nbno,
  'nl-be': nlnl,
  'nl-nl': nlnl,
  'pt-pt': ptpt,
  'sv-se': svse,
}

type NestedKeyOf<ObjectType extends object> =
{ [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
  ? never | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
  : `${Key}`
}[keyof ObjectType & (string | number)]

export type Translations = NestedKeyOf<TranslationMap>
