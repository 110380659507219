import packageJson from '../../package.json'

declare global {
  interface Window {
    COUPON_CALLOUT_COMPONENT_VERSION: string;
  }
}

const setVersionOnWindow = () => {
  window.COUPON_CALLOUT_COMPONENT_VERSION = packageJson.version
}

export { setVersionOnWindow }
